import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { COLORS, IMAGES } from "../constants";
import axios from "axios";
import { useMerchantStore } from "../store/merchant_store";
import { parse } from "qs";


const CartContext = createContext();

const initialState = {
  items: [],
};


// Function to extract the merchant name from the URL
const getMerchantFromURL = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("merch"); // This will return the value after merch=
};


const merchantData = JSON.parse(localStorage.getItem("merchantData"));

// Check if merchantData exists and retrieve the slug
// current url slug

// Get the merchant name from the URL
const merchantNameFromURL = getMerchantFromURL();
//const merchantSlug = merchantData ? merchantData.slug : null;

const merchantSlug = merchantNameFromURL ? merchantNameFromURL : null;

//console.log('this slug ',merchantSlug);


// Cart reducer function
const cartReducer = (state, action) => {
  let updatedItems;

  switch (action.type) {
    case "ADD_TO_CART":
      updatedItems = [...state.items, action.payload];
      updateLocalStorage(updatedItems); // Removed merchantSlug from action
      return { ...state, items: updatedItems };

    case "REMOVE_FROM_CART":
      const itemIdToRemove = action.payload.id;
      const lastAddedIndex = state.items
        .slice()
        .reverse()
        .findIndex((item) => item.id === itemIdToRemove);
      if (lastAddedIndex !== -1) {
        updatedItems = [...state.items];
        updatedItems.splice(updatedItems.length - 1 - lastAddedIndex, 1);
        updateLocalStorage(updatedItems); // Removed merchantSlug from action
        return { ...state, items: updatedItems };
      }
      return state;

    case "CLEAR_CART":
      updateLocalStorage([]); // Removed merchantSlug from action
      return { ...state, items: [] };

    case "SET_CART":
      return { ...state, items: action.payload };

    default:
      return state;
  }
};


// Function to update local storage, now specific to each merchant
const updateLocalStorage = (items) => {
  if (!merchantSlug) {
    console.error("Merchant slug not available");
    return;
  }

  localStorage.setItem(`cartItems_${merchantSlug}`, JSON.stringify(items));
  
  const storedData = localStorage.getItem(`cartItems_${merchantSlug}`);
  // console.log("Updated stored data:", storedData);
};

// Main context
const CartProvider = ({ children }) => {
 
  const [stateLayout, setStateLayout] = useState("flex-start");
  const changeLayout = (data) => setStateLayout(data);

  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [products, setProducts] = useState([]); 
  const [totalCart, setTotalCart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allMerchantInfo, setAllMerchantInfo] = useState([]);
  const merchantStore = useMerchantStore((state) => state.merchant);
 

  useEffect(() => {
    if (merchantData?.slug) {
      fetchProducts();
    }
  }, [merchantData?.slug]); // Fetch products when merchant slug changes


  const fetchProducts = async () => {
    try {
  
      setAllMerchantInfo(merchantData);

      const response = await axios.get(
        `https://backoffice.route.money/api/v1/business/merchant/products/${merchantData.slug}`
      );
      setProducts(response.data.results);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch user single business data:", error);
    }
  };



  useEffect(() => {
    if (merchantSlug) {
      const storedItems = localStorage.getItem(`cartItems_${merchantSlug}`);
      if (storedItems && state.items.length === 0) {
        dispatch({
          type: "SET_CART",
          payload: JSON.parse(storedItems),
        });
      }
    }
  }, [merchantSlug]); // Only run when the merchant slug changes
  

  
  const calculateTotalAmount = () => {
    const totalAmount = state.items
      .map((item) => {
        // Parse offer_price and price as floats
        const offerPrice = parseFloat(item.offer_price);
        const price = parseFloat(item.price);
  
        // Determine the price to use: offer_price if valid and greater than 0, otherwise use price
        const priceToUse = !isNaN(offerPrice) && offerPrice > 0 ? offerPrice : price;
  
        // Return the price multiplied by the quantity if quantity exists, otherwise just the price
        return priceToUse * (item.quantity || 1);
      })
      .reduce((sum, element) => sum + element, 0);
  
    // Return the total amount, fixed to 2 decimal places
    return totalAmount.toFixed(2);
  };
  

  const getNumberOfItems = () => {
    return state.items ? state.items.length : 0;
  };

const getStorageNumberOfItems = () => {
  const cartMainData = localStorage.getItem(`cartItems_${merchantData?.slug}`);
  if (cartMainData) {
    const parsedCart = JSON.parse(cartMainData);
    return parsedCart.length;
  }
  return 0;
};


  const getItemDetails = () => {
    if(state.items){
      const itemDetails = {};
      state.items.forEach((item) => {
        if (itemDetails[item.id]) {
          itemDetails[item.id].quantity += 1;
        } else {
          itemDetails[item.id] = {
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: 1,
          };
        }
      });

      console.log(Object.values(itemDetails));
      return Object.values(itemDetails);
    }
  };

  const getPriceOfItem = (state, itemId) => {
    const item = state.items?.find((item) => item.id === itemId);
    return item ? item.price : null;
  };

  const getQuantityOfItem = (itemId) => {
    const item = state.items?.filter((item) => item.id === itemId);
    return item ? item.length : 0;
  };

  useEffect(() => {
    setTotalCart(getNumberOfItems());
  }, [state]);

  const [deviceFrame, setDeviceFrame] = useState(false);
  const includeDeviceFrame = () => setDeviceFrame(!deviceFrame);

 

  return (
    <CartContext.Provider
      value={{
        state,
        dispatch,
        calculateTotalAmount,
        getNumberOfItems,
        getItemDetails,
        getPriceOfItem,
        products,
        getQuantityOfItem,
        totalCart,
        loading,
        stateLayout,
        changeLayout,
        allMerchantInfo,
        merchantData,
        getStorageNumberOfItems,
        includeDeviceFrame,
        deviceFrame,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart };
