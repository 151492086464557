import { Dimensions, Appearance, useColorScheme } from "react-native";
import { ms } from "react-native-size-matters";

console.log(Appearance.getColorScheme());
// const LightAppTheme = Appearance.getColorScheme() == "light" // TODO: uncomment for light mode
const LightAppTheme = false;

export const COLORS = {
  backgroundDark: LightAppTheme ? "#EDEEF0" : "#1E202E",
  blue: "#1E72F2",
  white: LightAppTheme ? "#1E202E" : "#fff",

  backgroundLight: LightAppTheme ? "#fff" : "rgba(255, 255, 255, 0.2)",
  backgroundLighter: LightAppTheme ? "#fff" : "rgba(0, 0, 0, 0.161)",
  backgroundLightDark: "rgbargba(30, 114, 242, .2)",
  contactBackground: LightAppTheme ? "#fff" : "#1E202E",
  subtitlecolor: "rgba(136, 154, 166, 1)",
  red: "#FF0000",
  backgroundDarker: LightAppTheme
    ? "rgba(0, 0,0, 0.161)"
    : "rgba(255, 255, 255, 0.4)",
  backgroundLightest: LightAppTheme ? "#fff" : "rgba(255, 255, 255, 0.1)",
  backgroundLighttwo: LightAppTheme ? "#EDEEF0" : "rgba(255, 255, 255, 0.26)",
  backgroundLightthree: LightAppTheme ? "#fff" : "rgba(255, 255, 255, 0.161)",
  gray: "#83848E",
  grayText: "rgb(201, 197, 197)",
  green: "#11A20B",
};

export const FONTS = {
  fontFamilyBold: "AirbnbCerealWBd",
  fontFamilyRegular: "AirbnbCerealWBk",
  fontFamilyLight: "AirbnbCerealWLt",

  buttonSmall: {
    fontSize: ms(12),
    //fontFamily: "AirbnbCerealWBd",
    color: COLORS.white,
  },
  contentTitle: {
    fontSize: ms(18),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "700",
    color: COLORS.white,
  },
  contentTitleLight: {
    fontSize: ms(18),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "700",
    color: COLORS.white,
  },
  headingText: {
    fontSize: 34,
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "700",
    color: COLORS.white,
  },
  fineText: {
    fontSize: ms(14),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "300",
    color: COLORS.white,
  },
  veryFineText: {
    fontSize: ms(10),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "300",
    color: COLORS.white,
  },
  smallfineText: {
    fontSize: ms(12),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "500",
    color: COLORS.white,
  },
  verySmallfineText: {
    fontSize: ms(11),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "500",
    // color: COLORS.white,
  },
  subtext: {
    fontSize: ms(13),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "700",
    color: COLORS.white,
  },
  bigsubtext: {
    fontSize: ms(16),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "700",
    color: COLORS.white,
  },
  smallsubtext: {
    fontSize: ms(16),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "700",
    color: COLORS.white,
  },
  subsmalltext: {
    fontSize: ms(12),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "300",
    color: COLORS.white,
  },
  mediumfineText: {
    fontSize: ms(13),
    //fontFamily: "AirbnbCerealWBk",
    fontWeight: "500",
    color: COLORS.white,
  },
  mediumfineTextLight: {
    fontSize: ms(13),
    //fontFamily: "AirbnbCerealWLt",
    fontWeight: "500",
    color: COLORS.white,
  },
  digitText: {
    fontSize: ms(26),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "500",
    color: COLORS.white,
  },
  routeintro: {
    fontSize: ms(28),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "500",
    color: COLORS.white,
  },
  tabText: {
    fontSize: ms(9),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "500",
    color: COLORS.white,
  },
  smalltabText: {
    fontSize: ms(9),
    //fontFamily: "AirbnbCerealWBk",
    fontWeight: "500",
    color: COLORS.white,
  },
  keyboardMainText: {
    fontSize: ms(52),
    //fontFamily: "AirbnbCerealWBd",
    fontWeight: "700",
    color: COLORS.white,
  },
};

export const IMAGES = {
  faceid: require("../assets/images/faceid.png"),
  loc: require("../assets/images/loc.png"),
  delivery: require("../assets/images/delivery.png"),
  contact: require("../assets/images/contact.png"),
  tim: require("../assets/images/tim.png"),

  pay_route: require("../assets/images/pay_route.png"),


  pay_mpesa: require("../assets/images/pay_mpesa.png"),
  card_mpesa: require("../assets/images/cardpayment.svg"),
  pay_card: require("../assets/images/pay_card.png"),
  pay_delivery: require("../assets/images/pay_delivery.png"),

  gridicon: require("../assets/images/gridicon.png"),
  homeicon: require("../assets/images/homeicon.png"),
  transacticon: require("../assets/images/transacticon.png"),
  transaction: require("../assets/images/transaction.png"),
  user: require("../assets/images/user.png"),
  notificationicon: require("../assets/images/bellicon.png"),
  spliticon: require("../assets/images/spliticon.png"),
  sendicon: require("../assets/images/sendicon.png"),
  payicon: require("../assets/images/payicon.png"),
  scanicon: require("../assets/images/scan.png"),
  earnicon: require("../assets/images/earn.png"),
  rewardicon: require("../assets/images/reward.png"),
  randezvousicon: require("../assets/images/randeicon.png"),
  lockicon: require("../assets/images/lockicon.png"),
  eyeicon: require("../assets/images/eyeicon.png"),
  shieldicon: require("../assets/images/shieldicon.png"),
  fileicon: require("../assets/images/fileicon.png"),
  cautionicon: require("../assets/images/cautionicon.png"),
  helpicon: require("../assets/images/help.png"),
  logouticon: require("../assets/images/logout.png"),
  usericon: require("../assets/images/usericon.png"),
  moneyicon: require("../assets/images/moneyicon.png"),
  success: require("../assets/images/success.png"),
  instagram: require("../assets/images/instagram.png"),
  facebook: require("../assets/images/facebook.png"),
  twitter: require("../assets/images/twitter.png"),
  star: require("../assets/images/star.png"),
  introstar: require("../assets/images/introstar.png"),
  introbackground: require("../assets/images/introbackground.jpg"),
  website: require("../assets/images/website.png"),
  receipt: require("../assets/images/receipt.png"),
  kenya: require("../assets/images/kenya.png"),
  clear: require("../assets/images/clear.png"),
  backgroundWithDetails: require("../assets/images/backgroundone.png"),
  backgroundtwo: require("../assets/images/backgroundtwo.png"),
  backgroundthree: require("../assets/images/backgroundthree.png"),
  love: require("../assets/images/love.png"),
  loved: require("../assets/images/loved.png"),
  location: require("../assets/images/location.png"),
  calendar: require("../assets/images/calendar.png"),
  error: require("../assets/images/error.png"),
  success: require("../assets/images/success.png"),
  scansample: require("../assets/images/samplescan.png"),
  flashlight: require("../assets/images/flashlight.png"),
  explorepayicon: require("../assets/images/explorepayicon.png"),
  explorerewardsicon: require("../assets/images/explorerewardsicon.png"),
  exploresendicon: require("../assets/images/exploresendicon.png"),
  // brands
  travel: require("../assets/images/travel.png"),
  spassobrand: require("../assets/images/spassobran.png"),
  shell: require("../assets/images/shell.png"),
  nike: require("../assets/images/nike.png"),
  night: require("../assets/images/night.png"),
  madison: require("../assets/images/madison.png"),
  java: require("../assets/images/java.png"),
  food: require("../assets/images/food.png"),
  entertainment: require("../assets/images/entertainement.png"),
  dollar: require("../assets/images/dollar.png"),
  coffee: require("../assets/images/coffee.png"),
  sunset: require("../assets/images/sunset.png"),
  art: require("../assets/images/art.png"),
  gift: require("../assets/images/gift.png"),
  entertainment: require("../assets/images/entertainment.png"),
  dollarwrap: require("../assets/images/dollarwrap.png"),
  logoroute: require("../assets/images/logoroute.png"),
  notificationbellicon: require("../assets/images/notificationbellicon.png"),
  barnextdoor: require("../assets/images/barnextdoor.png"),
  adidas: require("../assets/images/adidas.png"),
  artcaffe: require("../assets/images/artcaffe.png"),
  cavalli: require("../assets/images/cavalli.png"),
  coffeecrust: require("../assets/images/coffeecrust.png"),
  radison: require("../assets/images/radison.png"),
  rubis: require("../assets/images/rubis.png"),
  yallo: require("../assets/images/yallo.png"),
  barnextdoorbackground: require("../assets/images/barnextdoorbackgroung.png"),
  logo: require("../assets/images/routelogo.svg"),
  // logo: require("../assets/images/routelogo.png"),
  bottle1: require("../assets/images/Rectangle 347.jpg"),
  bottle2: require("../assets/images/Rectangle 346.jpg"),
  bottle3: require("../assets/images/Rectangle 344.jpg"),
  bottle4: require("../assets/images/Rectangle 333.png"),
  bottle5: require("../assets/images/Rectangle 345.jpg"),
  fullfood: require("../assets/images/fullfood.png"),
};

export const MAINLOGO ={
  logo3:require('../assets/images/prayer-2.svg'),
}

// update this when light mode colors are out
export const THEME = {};

const { width, height } = Dimensions.get("screen");
// export const cardWithSize = (385 / 430) * width;
export const cardWithSize = (404 / 430) * width;
export const homecardWithSize = (404 / 430) * width;
