import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import MainNavigation from "./MainNavigation";
import { CartProvider } from "../context/SinglecartContext";
import { GroupCartProvider } from "../context/GroupmanagerContext";
import { MerchantTokenProvider } from "../context/HandleAuthendication";
import { SplitProvider } from "../context/SplitBillsContext";
import { LocationProvider } from "../context/GeoLocation";

const StackNavigation = () => {
  return (
    <NavigationContainer>
      <MerchantTokenProvider>
        <LocationProvider>
          <SplitProvider>
            <GroupCartProvider>
              <CartProvider>
                <MainNavigation />
              </CartProvider>
            </GroupCartProvider>
          </SplitProvider>
        </LocationProvider>
      </MerchantTokenProvider>
    </NavigationContainer>
  );
};

export default StackNavigation;
