import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Dimensions,
  TouchableOpacity,
  Image,
  FlatList,
  Alert,
  Modal,
  Picker,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import React, { useState, useEffect } from "react";
import { ArrowLeft, Plus } from "lucide-react";
import Button from "../../../components/Button";

import { X } from "lucide-react";
import { TextInput } from "react-native-web";
import { useCart } from "../../../context/SinglecartContext";
import Layouts from "../../Layouts/ScreenView";
import { useSplitContext } from "../../../context/SplitBillsContext";
import moment from 'moment';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import axios from "axios";
import { HandleTransactions } from "../../../context/TransactionContext";
import "../../../assets/script";
import qs from "qs";
import { useGroupCart } from "../../../context/GroupmanagerContext";
import { WebView } from "react-native-webview";
import { TablePagination } from "@material-ui/core";

// import { InAppBrowser } from "react-native-inappbrowser-reborn";

function RecieptScreen({ navigation, route, layoutState }) {
  const { state, calculateTotalAmount, getQuantityOfItem, stateLayout } =
    useCart();
  const {
    createGroup,
    joinGroup,
    leaveGroup,
    addToCart,
    removeFromCart,
    clearCart,
  } = useGroupCart();
  const {
    openSplitBillModal,
    openCustomizePeopleToSplitModal,
    openMediaModalToShareLinks,
    splitBillModal,
    sharePlitLink,
    customizePeopleAmPayingFor,
    ontableCount,
    peopleAmPayingFor,
    generateUrl,
    requestedAmount,
    myPayments,
  } = useSplitContext();
//   const { billStatus, showPersonaStatus, product } = route.params;
  const { allMerchantInfo } = route.params || {};
  const merchantData = JSON.parse(localStorage.getItem("merchantData"));
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading]=useState(false);
  const [cardloading, setCardLoading] = useState(false)
  const [delloading, setDelloading] = useState(false)
  const [paymentOptionsPlan, setPaymentOptionPlan] = useState('');
//  console.log("state: ", state);

// create dummy receipt no

// current date
  // Get the current time using Moment.js
  const currentTime = moment().format('HH:mm');


  // Split bills >> Split equally
  const ratings = parseFloat(
    (
      calculateTotalAmount() -
      (calculateTotalAmount() - calculateTotalAmount() * 0.116)
    ).toFixed(2)
  );
  // const amountToSplit = (parseFloat(calculateTotalAmount()) + ratings).toFixed(
  //   2
  // );
    const amountToSplit = parseFloat(calculateTotalAmount());
    const amountWithoutTax = parseFloat(calculateTotalAmount());


  // Informations
// const [loading, setLoading]= useState(true);
 


  const onWebModalClose = () => {
    setIsWebVisible(false);
  };

    // Aggregate items by product ID
    const aggregatedItems = state.items.reduce((acc, item) => {
      if (acc[item.id]) {
        acc[item.id].quantity += item.quantity; // Sum quantities
      } else {
        acc[item.id] = { ...item }; // Initialize entry
      }
      return acc;
    }, {});
  
    // Convert aggregated items to an array
    const uniqueItems = Object.values(aggregatedItems);
  


  const [responseMessage, setResponseMessage] = React.useState(null);
  responseMessage ? alert(responseMessage) : null;

  const [paymentModalDisplay, setpaymentModalDisplay] = useState(false);
  const openPayModal = () => setpaymentModalDisplay(true);
  const closePayModal = () => setpaymentModalDisplay(false);

  const [currentOrderId, setCurrentOrderId]= useState(null);
  const [currentOrder, setCurrentOrder] = useState({})


      useEffect(() => {
        const currentURL = window.location.href;
        const urlParams = new URLSearchParams(currentURL.split('?')[1]);
        const searchOrderParam = urlParams.get('searchOrder');
    
        // Only update state if the searchOrderParam is different from the current state
        if (searchOrderParam && searchOrderParam !== currentOrderId) {
          setCurrentOrderId(searchOrderParam);
          
          console.log("This is the condition for searchOrder:", searchOrderParam);
          // You can add your logic here, like navigation or fetching order details
        }
      }, [currentOrderId]); 


  // Enable equalsplit & custom split
  const [equalSplit, setEqualSplit] = useState(false);
  const buttonsData = [
    { id: 1, name: "IN-STORE" },
    { id: 2, name: "RESERVE" },
    { id: 3, name: "PICK-UP" },
    { id: 4, name: "DELIVERY" },
  ];
  // const [paymentOptionsPlan, setPaymentOptionPlan] = useState('Dine In');
  const handlePaymentTypes = (name) => {
    name ? setPaymentOptionPlan(name) : null;
  };

  const [count, setCount] = useState(0);
  // Transaction function

  

  useEffect(() => {
    // Only fetch if currentOrderId is not null
    if (currentOrderId) {
        setLoading(true);
      const fetchOrder = async (orderId) => {
        try {
          const orderurl = `https://backoffice.route.money/api/v1/business/order/details/${orderId}`;
          const response = await axios.get(orderurl);
          const data = response.data;
          console.log('order data ', data);
          setCurrentOrder(data);
        } catch (error) {
          console.error('Error fetching order:', error);
        }
        finally{
            setLoading(false);
          }
      } 

      fetchOrder(currentOrderId); // Pass the orderId here
    }
  }, [currentOrderId]); // This effect runs only when currentOrderId changes


  const navigateToHome = () => {
    let currentURL = window.location.href;
    const url = new URL(currentURL);
    url.searchParams.delete('searchOrder');
    const updatedURL = url.toString();

    window.history.replaceState(null, '', updatedURL);
    navigation.navigate("HomeScreen");
  };


  return (
    <Layouts>
      <>
        <View style={styles.container}>
          <View style={styles.navigationLinks}>
            {/* <TouchableOpacity onPress={() => navigation.goBack()}>
              {/* <ArrowLeft color="white" size={20} />
            </TouchableOpacity> */} 
            <View style={styles.footerimagecontainer}>
              <Image
                source={IMAGES.logo}
                style={styles.qrimage}
                resizeMode="contain"
              />
            </View>
            <View></View>
          </View>

          <View style={styles.bottomContentView}>
            <View style={styles.topBillHeader}>
              {/* Equal split & Custom split */}
             
                <Text style={styles.billTitle}>Order Details</Text>
            </View>
            <View style={styles.billRecieptsection}>
              <View style={styles.cardTitle}>
              {loading && <ActivityIndicator size={45} color="green"/>}
                  <Text style={{ ...FONTS.bigsubtext, fontSize: 14 }}>
                  <Text style={styles.textClrs}>order No. {currentOrder.order_number}</Text> 
                  </Text>
              
              </View>

              <View style={styles.hoursummary}>
                 <View style={styles.hourSummaryTop}>
           
                </View> 
                <Text style={styles.textClrs}>Order Type - {currentOrder.order_type} Hrs</Text>
              </View>

              <View style={styles.hoursummary}>
                 <View style={styles.hourSummaryTop}>
                 <Text style={styles.textClrs}>Customer name. {currentOrder.customer_name}</Text> 
                </View> 
                <View style={styles.hourSummaryTop}>
                 <Text style={styles.textClrs}>Phone. {currentOrder.phone_number}</Text> 
                </View> 
                </View>

              <View style={styles.hoursummary}>
                <View style={styles.categoriesReciept}>
                  <View style={styles.firstCartegory}>
                    <Text style={styles.itemsCartegoriesHeader}>QTY</Text>
                    <Text
                      style={[
                        styles.itemsCartegoriesHeader,
                        { marginLeft: 50 },
                      ]}
                    >
                      ITEM
                    </Text>
                  </View>
                  <Text style={styles.itemsCartegoriesHeader}>PRICE</Text>
                </View>

        {/* display te items here */}
        {currentOrder.order_items && currentOrder.order_items.map((item, index) => (
        <View key={index} style={styles.categoriesReciept}>
          <View style={styles.firstCartegory}>
            <Text style={styles.viatText}>{item.quantity}</Text>
            <Text style={[styles.viatText, { marginLeft: 50 }]}>{item.product_name}</Text>
          </View>
          <Text style={styles.viatText}> {parseFloat(item.price).toFixed(2)}</Text>
        </View>
      ))}

              </View>

              <View style={[styles.hoursummary, { marginTop: 5 }]}>
                <View style={styles.subViat}>
                  <Text style={styles.viatText}>Grand Total</Text>
                  <Text style={styles.viatText}>Ksh. {parseFloat(currentOrder.total_amount).toFixed(2)}</Text>
                </View>
                <View style={styles.subViat}>
              
                  <Text style={styles.viatText}>
                 
                    {/* {"0.00"} */}
                  </Text>
                </View>
              </View>

              {/* <View style={[styles.hoursummaryFooter, { marginTop: 10 }]}>
                <View style={styles.subViat}>
                  <Text style={styles.viatText}>GRAND TOTAL</Text>
                  <Text style={styles.viatText}>Ksh</Text>
                </View>
                <View style={styles.subViat}>
        
                </View>
              </View> */}
            </View>
       
            <View style={styles.buttoncontainer}>
          <Button text="Home" onPress={navigateToHome} style={styles.button} />

              </View>

          </View>
   
      
        </View>
      </>
    </Layouts>
  );
}

{
  /* webview Modal */
}
const WebBrowserModal = ({ isVisible, children, onClose }) => {
  return (
    <Modal animationType="slide" transparent={true} visible={isVisible}>
      <ScrollView style={styles.modalContent}>
        <View style={styles.titleContainer}>
          <Pressable onPress={onClose}>
            <X stroke="white" fill="transparent" width={16} height={16} />
          </Pressable>
        </View>
        {children}
      </ScrollView>
    </Modal>
  );
};

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  // Modal styles
  customerModalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "relative",
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },
  customerModalContent: {
    backgroundColor: COLORS.backgroundDark,
    width: "100%",
    height: hp(50),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  paymentsInputs: {
    marginTop: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.backgroundLight,
    marginHorizontal: 4,
    color: COLORS.gray,
    border: "none",
    width: wp(90),
  },
  //
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
  },
  navigationLinks: {
    width: "100%",
    position: "absolute",
    top: 0,
    paddingHorizontal: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: 10,
  },

  bottomContentView: {
    width: "100%",
    paddingHorizontal: 10,
  },
  bottomLinksBtns: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    flexDirection: "row",
    bottom: 10,
    left: 0,
    right: 0,
    maxWidth: 430,
  },
  buttoncontainer: {
    paddingTop:130,
    alignItems: 'center',  // Centers the button horizontally
    justifyContent: 'center', // Ensure content is centered vertically if needed
    width: '100%', 
  },
//   button: {
//     backgroundColor: COLORS.blue,
//     borderWidth: 1,
//     borderColor: COLORS.blue,
//     justifyContent: "center",
//     marginVertical: 10,
//     width: 290,
//   },
  button: {
    backgroundColor: COLORS.blue,
    borderWidth: 1,
    borderColor: COLORS.blue,
    width: '80%', // or any percentage that works for your design
    maxWidth: 300,  // Optional: limits the maximum size for larger screens
    alignSelf: 'center',  // Ensures the button aligns itself in the center if needed
  },

  footerimagecontainer: {
    width: 160,
    height: 50,
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: 5,
    marginVertical: 20,
  },
  qrimage: {
    width: "100%",
    height: "100%",
  },

  bottomContentView: {
    width: "100%",
    height: "70%",
    maxHeight: "70%",
    paddingHorizontal: 10,
  },
  topBillHeader: {
    paddingVertical: 10,
  },
  billTitle: {
    ...FONTS.bigsubtext,
    fontSize: 14,
  },
  billRecieptsection: {
    backgroundColor: COLORS.backgroundLight,
    width: "100%",
    padding: 10,
    borderRadius: 21,
    marginVertical: 10,
  },
  cardTitle: {
    width: "100%",
    paddingVertical: 15,
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
  },
  hoursummary: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 5,
  },
  hoursummaryFooter: {
    paddingVertical: 5,
  },
  hourSummaryTop: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  textClrs: {
    ...FONTS.fineText,
    fontSize: 11,
    paddingVertical: 2,
  },
  categoriesReciept: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  firstCartegory: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemsCartegoriesHeader: {
    ...FONTS.bigsubtext,
    fontSize: 10,
  },
  categoriesRecieptValue: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 5,
  },
  firstCartegoryValue: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemsCartegoriesHeaderValue: {
    ...FONTS.bigsubtext,
    fontSize: 10,
  },
  subViat: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 5,
  },
  viatText: {
    ...FONTS.bigsubtext,
    fontSize: 10,
  },

  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingTop: 5,
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },

  modalContent: {
    backgroundColor: COLORS.backgroundDark,
    width: "100%",
    height: "95%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    paddingHorizontal: 5,
  },

  madalPayAmount: {
    width: "100%",
    height: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  modelTextColor: {
    paddingVertical: 5,
    width: "100%",
    textAlign: "center",
    ...FONTS.bigsubtext,
  },
  barTopSectionView: {
    borderTopWidth: 3,
    height: 1,
    borderTopColor: "white",
    width: 52,
    borderRadius: 100,
    marginBottom: 5,
    marginTop: -20,
  },
  topCloseModal: {
    width: "100%",
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  windowDisplay: {
    width: "100%",
    flex: 1,
    paddingVertical: 5,
  },
  topHeader: {
    justifyContent: "center",
    alignItems: "center",
  },
  buttonSections: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 1,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
  btns: {
    paddingHorizontal: 2,
    paddingVertical: 10,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    width: 70,
    marginHorizontal: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  texts: {
    ...FONTS.fineText,
    fontSize: 10,
  },
  paymentsAmountDisplay: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  textBillSection: {
    ...FONTS.smalltabText,
    paddingVertical: 5,
  },
  inputs: {
    marginTop: 5,
    maxWidth: 430,
  },
  inputsSendMethods: {
    marginTop: 10,
    maxWidth: 430,
    backgroundColor: COLORS.backgroundLight,
    paddingHorizontal: 10,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 10,
  },
  paymentsAmountDisplays: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  singleButton: {
    width: 60,
    height: 80,
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 5,
  },
  imagePay: {
    width: 30,
    height: 30,
    borderRadius: 100,
    backgroundColor: COLORS.gray,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  imagePayIcon: {
    width: "100%",
    height: "100%",
  },
  billtPersonalView: {
    paddingHorizontal: 15,
  },
  billtPersonalViewTextStatus: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  singlePersonBillView: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 5,
  },
  paidStatus: {
    backgroundColor: COLORS.white,
    paddingVertical: 2,
    paddingHorizontal: 5,
    borderRadius: 100,
    fontSize: 9,
    width: 58,
    textAlign: "center",
  },

  // Split bill modal
  modalContainerGroupPayment: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: "35%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  modalButtonOrderGroupChart: {
    marginVertical: 10,
  },
  buttonGroup: {
    width: "96%",
    marginVertical: 10,
    fontSize: 14,
  },
  groupTitle: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
  },

  // Customize people split
  madalCustomizePeopleSplit: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: "45%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  peopleCustomSection: {
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 15,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
  },
  incrementDecrementPeople: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  numberOfPeople: {
    marginHorizontal: 5,
    ...FONTS.smallfineText,
    fontSize: 13,
  },
  peopleBtns: {
    width: 15,
    height: 15,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  colorsBtns: {
    textAlign: "center",
    ...FONTS.smallfineText,
    fontSize: 11,
  },

  // Equal split amount model
  modalContentEqualSplit: {
    backgroundColor: COLORS.backgroundDark,
    width: "100%",
    height: "50%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    paddingHorizontal: 20,
    paddingVertical: 20,
  },

  windowDisplay: {
    width: "100%",
    flex: 1,
    paddingVertical: 5,
  },
  groupTitle: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 40,
    maxWidth: 430,
  },
  modalButtonOrderGroupChart: {
    marginVertical: 10,
  },
  singleInput: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: 5,
    color: COLORS.gray,
    border: "none",
  },
});
export default RecieptScreen;
