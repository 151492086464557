import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  Image,
  FlatList,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState } from "react";
import { ArrowLeft, Route } from "lucide-react";
import Button from "../../../components/Button";
import SingleCartProduct from "./components/SingleCartProduct";
import { useCart } from "../../../context/SinglecartContext";
import LinearGradient from "react-native-web-linear-gradient";
import Layouts from "../../Layouts/ScreenView";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import axios from "axios";
import { useMerchantStore } from "../../../store/merchant_store";
// import { ScrollView } from "react-native-web";
console.log("entering the cart main");
function CartScreen({ navigation, route }) {
  const merchantStore = useMerchantStore((state) => state.merchant);
  const { getNumberOfItems } = route.params;
  const { merchantData } = route.params || merchantStore;
  const [allMerchantInfo, setallMerchantInfo] = useState([]);
 // console.log("merchant being queried", merchantData);


  useEffect(() => {
    fetchAllMerchantInfo();
  }, []);

  const fetchAllMerchantInfo = async () => {
    //console.log("merchants id", merchantData.id);

    setallMerchantInfo(merchantData);
    try {
      const response = await axios.get(
        `https://backoffice.route.money/api/v1/business/businesses/${merchantData.id}`
      );
      // setallMerchantInfo(response.data);

     // console.log("allMerchantInfo: ", response.data);
    } catch (error) {
     // console.error("Failed to fetch merchant data:", error);
    }
  };

  // Const navigate back to orders
  const navigateBackToAddProducts = () => {
    navigation.goBack();
  };

  // Displaying cart products
  const { state, calculateTotalAmount, totalCart, getQuantityOfItem } =
    useCart();

  // create a new set of items from the state
  const items = new Set();
  state.items.forEach((item) => items.add(item));
  //console.log("items", items);
  // convert the set back to an array
  const itemsArray = state.items.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id && t.name === value.name
    ))
  )
  const ratings = parseFloat(
    (
      calculateTotalAmount() -
      (calculateTotalAmount() - calculateTotalAmount() * 0.116)
    ).toFixed(2)
  );

  // Paying while placing the order
  const navigateToPayBills = (product, allMerchantInfo) => {
    localStorage.removeItem(`cartItems_${merchantData.slug}`);
    localStorage.removeItem('imageSizes');
    navigation.navigate("RecieptScreen", {
      getNumberOfItems,
      billStatus: false,
      product: product,
      merchantData,
      allMerchantInfo,
    });
  };

  return (
    <Layouts>
      <View style={styles.container}>
        <View style={styles.navigationLinks}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <ArrowLeft color="white" size={18} />
          </TouchableOpacity>
        </View>

        {/* container */}
        {merchantData ? (
          <View style={styles.offerViewPage}>
            <View style={styles.topImageView}>
              <View style={styles.imagecover}>
                <Image
                  source={`https://backoffice.route.money/api/v1${merchantData.cover_image}`}
                  // source={`https://routemoney.deveint.live/api/v1${merchantData.cover_image}`}
                  style={styles.imageset}
                />
              </View>
              <LinearGradient
                colors={[
                  "rgba(30, 32, 46, 1.1)",
                  "rgba(30, 32, 46, 1.0)",
                  "rgba(30, 32, 46, .95)",
                  "rgba(30, 32, 46, .80)",
                  "rgba(30, 32, 46, .70)",
                  "rgba(30, 32, 46, .60)",
                  "rgba(30, 32, 46, .50)",
                  "rgba(30, 32, 46, .40)",
                  "rgba(30, 32, 46, .30)",
                  "rgba(30, 32, 46, .20)",
                  "rgba(30, 32, 46, .10)",
                  "rgba(30, 32, 46, .05)",
                  "rgba(30, 32, 46, .04)",
                  "rgba(30, 32, 46, .03)",
                  "rgba(30, 32, 46, .02)",
                  "rgba(30, 32, 46, .01)",
                  "rgba(30, 32, 46, .00)",
                ]}
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                angle={180}
                style={styles.imageZindexValues}
              >
                <View style={styles.avatarImageDisplay}>
                  <Image
                    source={`https://backoffice.route.money/api/v1${merchantData.profile_image}`}
                    // source={`https://routemoney.deveint.live/api/v1${merchantData.profile_image}`}
                    style={styles.imageset}
                  />
                </View>
                <View style={styles.textPreview}>
                  <Text style={[styles.title, { fontWeight: 700 }]}>
                    {merchantData.name}
                  </Text>
                  <Text style={styles.title}>{merchantData.location}</Text>
                </View>
              </LinearGradient>
            </View>

            <View style={styles.bottomContentView}>
              <View style={styles.topCartHeader}>
                <Text style={styles.cartHeading}>Personal cart</Text>
                <Text style={styles.cartHeading}>Orders ({totalCart})</Text>
                <TouchableOpacity
                  style={styles.addButtonCart}
                  onPress={navigateBackToAddProducts}
                >
                  <Text style={{ ...FONTS.fineText, fontSize: 14 }}>
                    + Add Items
                  </Text>
                </TouchableOpacity>
              </View>

              {/* Offers items */}
              {itemsArray.length !== 0 ? (
                <View style={styles.allCartProductsDisplay}>
                  <ScrollView
                    style={styles.productsDisplay}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                  >
                    {/* Render Products */}
                    {itemsArray.map((item, idx) => {
                      return <SingleCartProduct data={item} key={idx} />;
                    })}
                  </ScrollView>
                  <View style={styles.summaryPayments}>
                    <View style={styles.topVATandSubTotal}>
                      <View style={styles.subtoal}>
                        <Text style={styles.subtexts}>Sub Total</Text>{" "}
                        <Text style={styles.subtexts}>
                          {parseFloat(calculateTotalAmount()).toFixed(2)}
                        </Text>
                      </View>
                      {/* <View style={styles.subtoal}>
                        <Text style={styles.subtexts}>VAT 16%</Text>{" "}
                        <Text style={styles.subtexts}>{ratings}</Text>
                      </View> */}
                    </View>
                    <View style={styles.subtoal}>
                      <Text
                        style={[
                          styles.subtexts,
                          { fontWeight: 500, paddingVertical: 5 },
                        ]}
                      >
                        Total
                      </Text>
                      <Text
                        style={[
                          styles.subtexts,
                          { fontWeight: 500, paddingVertical: 5 },
                        ]}
                      >
                        Ksh.{" "}
                        {/* {(parseFloat(calculateTotalAmount()) + ratings).toFixed(
                          2
                        )} */}
                        {parseFloat(calculateTotalAmount()).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <View style={styles.allCartProductsDisplay}>
                  <Text
                    style={{
                      ...FONTS.smallsubtext,
                      color: "orange",
                      fontSize: 13,
                    }}
                  >
                    Oops!! No items available, Click the Add Items buttons on
                    top-right of the cart to add items in your cart
                  </Text>
                </View>
              )}

              {/* Bottom buttons */}
              <View style={styles.bottomLinksBtns}>
                {/* {state.items.length !== 0 && (
                  <Button
                    text="Bring to Table"
                    style={[styles.button, { backgroundColor: "transparent" }]}
                    onPress={() => navigation.goBack()}
                  />
                )} */}
                {allMerchantInfo ? (
                  <Button
                    text={
                      state.items.length === 0
                        ? "Add items to cart to pay!!!"
                        : "Pay"
                    }
                    style={styles.button}
                    onPress={
                      state.items.length === 0
                        ? () => navigation.goBack()
                        : () => navigateToPayBills(state.items, allMerchantInfo)
                    }
                  />
                ) : (
                  <ActivityIndicator />
                )}
              </View>
            </View>
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </Layouts>
  );
}

const styles = StyleSheet.create({
  container: {
    width: wp(100),
    height: hp(100),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
  },
  navigationLinks: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    paddingVertical: 15,
    zIndex: 1,
  },
  offerViewPage: {
    width: "100%",
    height: "100%",
    margin: 0,
  },
  topImageView: {
    width: "100%",
    height: hp(20),
    position: "relative",
  },
  imagecover: {
    width: "100%",
    height: "100%",
  },
  barnextdoorbackground: {
    width: "100%",
    height: "100%",
  },
  imageZindexValues: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 10,
  },
  avatarImageDisplay: {
    width: 40,
    height: 40,
    borderRadius: 100,
    overflow: "hidden",
    backgroundColor: COLORS.gray,
  },
  imageset: {
    width: "100%",
    height: "100%",
  },
  textPreview: {
    marginLeft: 10,
  },
  title: {
    ...FONTS.fineText,
    fontSize: 14,
  },
  bottomContentView: {
    width: "100%",
    paddingHorizontal: 10,
    flex: 1,
  },
  topCartHeader: {
    width: "100%",
    paddingVertical: 5,
    marginVertical: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  cartHeading: {
    ...FONTS.bigsubtext,
    fontSize: 13,
  },
  addButtonCart: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    ...FONTS.fineText,
  },
  allCartProductsDisplay: {
    width: "100%",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 10,
    maxHeight: "60%",
    position: "relative",
  },
  productsDisplay: {
    width: "100%",
    maxHeight: hp(30),
    overflow: "auto",
    scrollbars: "none",
  },
  summaryPayments: {
    paddingVertical: 1,
  },
  topVATandSubTotal: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
  },
  subtoal: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 2,
  },
  subtexts: {
    ...FONTS.smallfineText,
    fontWeight: 300,
    fontSize: 14,
  },
  bottomLinksBtns: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  button: {
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 5,
    width: "90%",
  },
});
export default CartScreen;
