import React, { createContext, useContext, useState } from "react";
import { useMerchantStore } from "../store/merchant_store";

const SplitContext = createContext();

const merchantData = JSON.parse(localStorage.getItem("merchantData"));

const SplitProvider = ({ children }) => {
  const [totalAmount, setTotalAmount] = useState(null);
  const [individuals, setIndividuals] = useState([]);
  const [splitBillModal, setSplitBillModal] = useState(false);
  const [sharePlitLink, setShareSplitLink] = useState(false);
  const [mediaSplitshareLinks, setMediaSplitShareLink] = useState(false);

  const addIndividual = (name) => {
    setIndividuals((prevIndividuals) => [
      ...prevIndividuals,
      {
        id: Math.random(),
        name,
        amount: 0,
        isPaid: false,
        timestamp: new Date().toISOString(),
      },
    ]);
  };

  const splitEqually = () => {
    const totalIndividuals = individuals.length;
    const equalAmount = totalAmount / totalIndividuals;
    const updatedIndividuals = individuals.map((individual) => ({
      ...individual,
      amount: equalAmount,
    }));
    setIndividuals(updatedIndividuals);
  };

  const customizeAmount = (id, amount) => {
    const updatedIndividuals = individuals.map((individual) => {
      if (individual.id === id) {
        const newAmount = Math.min(amount, totalAmount);
        return { ...individual, amount: newAmount };
      }
      return individual;
    });
    setIndividuals(updatedIndividuals);
  };

  const markAsPaid = (id) => {
    const updatedIndividuals = individuals.map((individual) => {
      if (individual.id === id) {
        return { ...individual, isPaid: true };
      }
      return individual;
    });
    setIndividuals(updatedIndividuals);
  };

  // Open spllit bill modal
  const openSplitBillModal = () => setSplitBillModal(!splitBillModal);

  // Open customize people to split modal
  const openCustomizePeopleToSplitModal = (amount) => {
    amount && setTotalAmount(amount);
    setSplitBillModal(false);
    setShareSplitLink(!sharePlitLink);
  };

  // Paying for
  const [ontableCount, setOnTableCount] = useState(0);
  const [peopleAmPayingFor, setPeopleAmPayingFor] = useState(0);

  const customizePeopleAmPayingFor = (data) => {
    const { sign, id } = data;
    if (sign === "+") {
      id === "paying" && setPeopleAmPayingFor((prev) => prev + 1);
      id === "table" && setOnTableCount((prev) => prev + 1);
    } else if (sign === "-") {
      id === "paying" &&
        peopleAmPayingFor > 0 &&
        setPeopleAmPayingFor((prev) => prev - 1);
      id === "table" && ontableCount > 0 && setOnTableCount((prev) => prev - 1);
    } else {
      console.log("Some value attained!!!");
    }
  };

  const [requestedAmount, setRequestedAmount] = useState(null);
  const [myPayments, setMyPayments] = useState(null);
  const merchantStore = useMerchantStore((state) => state.merchant);

  const generateUrl = (orders) => {
    const queryString = encodeURIComponent(JSON.stringify(orders));
    const baseURL = `https://backoffice.route.money/api/v1/`;
    const shareableURL = `${baseURL}&order=${queryString}`;
    return shareableURL;
  };

  const openMediaModalToShareLinks = () => {
    if ((ontableCount > 0 || peopleAmPayingFor > 0) && totalAmount > 0) {
      const individuals = parseFloat((totalAmount / ontableCount).toFixed(2));
      setRequestedAmount(individuals);
      let myAmount = individuals;
      if (peopleAmPayingFor > 0) {
        myAmount *= 1 + peopleAmPayingFor;
      }
      setMyPayments(peopleAmPayingFor > 0 ? myAmount.toFixed(2) : individuals);

      setSplitBillModal(false);
      setShareSplitLink(false);
      setMediaSplitShareLink(!mediaSplitshareLinks);
    }
  };

  return (
    <SplitContext.Provider
      value={{
        totalAmount,
        individuals,
        addIndividual,
        splitEqually,
        customizeAmount,
        markAsPaid,
        openSplitBillModal,
        openCustomizePeopleToSplitModal,
        openMediaModalToShareLinks,
        splitBillModal,
        sharePlitLink,
        mediaSplitshareLinks,
        customizePeopleAmPayingFor,
        ontableCount,
        peopleAmPayingFor,
        generateUrl,
        requestedAmount,
        myPayments,
        totalAmount,
      }}
    >
      {children}
    </SplitContext.Provider>
  );
};

const useSplitContext = () => {
  return useContext(SplitContext);
};

export { SplitProvider, useSplitContext };
