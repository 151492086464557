import {AppRegistry} from 'react-native';
import App from './App';
import appConfig from './app.json'; 

// Register the app for the web platform
if (window.document) {
  const rootTag = document.getElementById('root') || document.getElementById('app');

  AppRegistry.registerComponent(appConfig.name, () => App); 
  AppRegistry.runApplication(appConfig.name, {rootTag}); 
}
