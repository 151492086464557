import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, Text} from "react-native";
import React from "react";

function SingleItemReport({data, getQuantityOfItem}) {

  //console.log('item info ',data);
  const quantity = getQuantityOfItem(data.id);
  const priceToUse = parseFloat(data.offer_price) > 0 ? parseFloat(data.offer_price) : parseFloat(data.price);
  const totalPrice = quantity * priceToUse;

  return (
    <View style={styles.categoriesRecieptValue}>
        <View style={styles.firstCartegoryValue}>
            <Text style={[styles.itemsCartegoriesHeaderValue, {paddingLeft: 5}]}>{getQuantityOfItem(data.id)}x</Text>
            <Text style={[styles.itemsCartegoriesHeaderValue, {marginLeft: 60}]}>{data.name}</Text>
        </View>
        {/* <Text style={styles.itemsCartegoriesHeaderValue}>{data.price}</Text> */}
        <Text style={styles.itemsCartegoriesHeaderValue}>{totalPrice.toFixed(2)}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  categoriesRecieptValue: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 5,
  },
  firstCartegoryValue: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemsCartegoriesHeaderValue: {
    ...FONTS.bigsubtext,
    fontSize: 10,

  },
});
export default SingleItemReport;
