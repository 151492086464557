import React, { useState, useCallback } from "react";
import { StyleSheet, View, TextInput, TouchableOpacity, ActivityIndicator } from "react-native";
import { debounce } from "lodash";
import { COLORS } from "../constants";
import { Search } from "lucide-react";

const SearchBar = ({ searchTerm, onChangeText, onSearch, isSearching }) => {
  
  // Debounced search handler using useCallback to ensure the function reference remains stable
  const debouncedSearch = useCallback(
    debounce((query) => {
      onSearch(query); // Trigger search after debounce
    }, 900), // Adjust debounce delay as needed
    []
  );


  const handleInputChange = (text) => {
    onChangeText(text); 
    if (text.trim() === "") {
      onSearch(""); 
    } else {
      debouncedSearch(text); 
    }
  };


  return (
    <View style={styles.inputwrapper}>
      <View style={styles.QueryButton}>
        {isSearching ? (
          <ActivityIndicator size="small" color={COLORS.blue} style={styles.spinner} />
        ) : (
          <TouchableOpacity onPress={() => onSearch(searchTerm)} style={styles.searchButton}>
            <Search size={20} color={COLORS.white} />
          </TouchableOpacity>
        )}
      </View>
      <TextInput
        placeholder="Search"
        placeholderTextColor={COLORS.white}
        value={searchTerm}
        onChangeText={handleInputChange} // Use the debounced input handler
        style={styles.input}
      />
    </View>
  );
};

export default SearchBar;

const styles = StyleSheet.create({
  inputwrapper: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    paddingHorizontal: 5,
    paddingVertical: 3,
    width: "98%",
  },
  input: {
    height: 40,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: COLORS.white,
    marginLeft: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    flex: 1,
  },
});
