import { COLORS, FONTS } from "../../../constants/index";
import { StyleSheet, View, Text, Dimensions,Modal, TextInput,TouchableOpacity,Alert,ActivityIndicator } from "react-native";
import React, { useEffect, useState } from "react";
import { CheckCircle2, AlertCircle } from 'lucide-react';
import Layouts from "../../Layouts/ScreenView";
import Button from "../../../components/Button";
import { useCart } from "../../../context/SinglecartContext";
import { useGroupCart } from "../../../context/GroupmanagerContext";


function CheckoutComplete({ navigation, route }) {
  const { groupno, memberId, merchantData, totalAmountPaid, brandname, billStatus, paymentOptionsPlan } = route.params || {};
  const { dispatch } = useCart();
  const { clearCart } = useGroupCart();

  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionRef, setTransactionRef] = useState('');
  const [transactionMessage, setTransactionMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [orderId, setOrderId] = useState(null); // to hold the order ID from local storage
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [userRef, setUserRef] = useState('');
  const [insystemMessage, setInSystemMessage] = useState('');
  const [checkoutData, setCheckoutData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [erroMessages, setErrorMessage] = useState('')
  const storedCheckoutData = JSON.parse(localStorage.getItem('checkoutData')) || {};

 
   // Function to decode the third-party response from the URL

   const decodePaymentResponse = () => {
    const currentURL = window.location.href;
  
    const urlParams = new URLSearchParams(currentURL.split('?')[1]);
    const encodedResponse = urlParams.get('response');

   // console.log('checkout data ',localStorage.getItem('checkoutData')); // Check if it was stored

    // console.log('this is whatsapp message ',nots);

    if (encodedResponse) {
      try {
        const decodedURIComponent = decodeURIComponent(encodedResponse);
        //console.log('Decoded URI component:', decodedURIComponent);

        const decodedString = atob(decodedURIComponent);
  
        const jsonData = JSON.parse(decodedString);

       // console.log("decoded message ",jsonData);
  
        if (jsonData.resultCode === '0' || jsonData.mpesaReceipt) {
          // Payment successful
        //  console.log('mpesa receipt ',jsonData.mpesaReceipt);
        
          handlePaymentSuccess(jsonData);
        } else if (jsonData.errors && jsonData.errors.length > 0) {
          handlePaymentError(jsonData.errors[0].message);
        }
      } catch (error) {
        console.error('Error decoding or parsing the response:', error);
        setMessage('An error occurred while processing the payment.');
        setMessageColor('red');
        setInSystemMessage('Payment wasn\'t successful, please try again.');
      }
    } else {
      console.log('No response parameter found in the URL.');
      setMessage('Payment wasn\'t successful, please try again');
      setMessageColor('red');
      setInSystemMessage('Payment wasn\'t successful, please try again.');
    }
  };


  // Constants for retry configuration
const MAX_RETRIES = 5; // Maximum number of retry attempts
const RETRY_INTERVAL = 3000; // Interval in milliseconds (3 seconds)

const handlePaymentSuccess = (jsonData, retryCount = 0) => {
  setMessage('Payment Successful');
  setMessageColor('green');
  setAmount(jsonData.amount);
  const mpesaRef = jsonData.mpesaReceipt;


  if (mpesaRef) {
    // Mpesa reference found
    setTransactionRef(mpesaRef);
    // transaction_reference
    //storedCheckoutData.transactionReference = mpesaRef;
    storedCheckoutData.transaction_reference=mpesaRef;

    // Optionally store the updated checkoutData with transactionReference
    localStorage.setItem('checkoutData', JSON.stringify(storedCheckoutData));

    // Complete the order after receiving the Mpesa reference
    handleCompleteOrder(storedCheckoutData);
  } else {
    // Mpesa reference not found, retry
    if (retryCount < MAX_RETRIES) {
      setInSystemMessage(`Waiting for Mpesa reference... Attempt ${retryCount + 1}/${MAX_RETRIES}`);
      setTimeout(() => {
        console.log('Retrying to fetch Mpesa reference...');
        handlePaymentSuccess(jsonData, retryCount + 1); // Retry with incremented retry count
      }, RETRY_INTERVAL);
    } else {
      // Max retries reached, ask user to enter Mpesa reference manually
      setInSystemMessage('You have successfully paid, but we did not receive the Mpesa receipt. Please enter it manually.');
    }
  }
};

  // Function to handle payment errors
  const handlePaymentError = (errorMessage) => {
    setMessage(errorMessage);
    setMessageColor('orange');
    setInSystemMessage('Payment details not captured. \n Enter M-Pesa code and submit.');
  };

 
  const generalClearCart = () => {
    if (groupno && memberId) {
      clearCart(groupno);
    } else {
      dispatch({ type: 'CLEAR_CART', payload: groupno });
    }
  };

  const navigateToOffers = () => {
    generalClearCart();

    // Get the current URL and remove the &response parameter
    let currentURL = window.location.href;
    const url = new URL(currentURL);
    url.searchParams.delete('response');
    const updatedURL = url.toString();

    // console.log('Updated URL:', updatedURL);
    // window.history.replaceState(null, '', updatedURL);
    navigation.navigate("PaymentResponsescreen");
  };

  const handleReferenceSearch = async () => {
    // Retrieve stored checkout data
    const searchCheckoutData = JSON.parse(localStorage.getItem('checkoutData'));
    const merch= localStorage.getItem("merchantData");

    let user_id;

    if (merch) {
      const merchObj = JSON.parse(merch); // Parse the JSON string to an object
    //  console.log(merchObj.business); 
    user_id=merchObj.business || 0;
    } else {
      console.log("No merchant data found in local storage.");
    }
    // Extract user_id from storedCheckoutData
   // const user_id = searchCheckoutData ? searchCheckoutData.merchant_id : '';
    // const allData=localStorage.getItem('checkoutData');



    try {
        setChecking(true);

        // Construct the URL (if needed)
        const url = `https://backoffice.route.money/api/v1/wallets/transaction-status`;

        // Send POST request with user_id and userRef in the body
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ transaction_ref_no: userRef, user_id })
        });

        const responseData = await response.json();
        if (response.ok) {
            const reference = responseData.data.transaction.transaction.reference;
            
            const resAmount = responseData.data.transaction.transaction.amount;
            setTransactionRef(reference);
            setAmount(resAmount);

            // Attach the transaction reference to storedCheckoutData
            if (searchCheckoutData) {
              searchCheckoutData.transaction_reference= reference;


        // Now update the state with the new checkoutData
        setCheckoutData(searchCheckoutData); // This is asynchronous!
        
                // Trigger handleCompleteOrder with the updated storedCheckoutData
             handleCompleteOrder();
             

                setMessageColor('green');
                setMessage('Order completed successfully');
            }
        } else {
            setMessageColor('red');
            const errorMessage = responseData.data.error;
            setInSystemMessage('There must be a problem with this request, please try again later');
        }
    } catch (error) {
        setMessageColor('red');
        setMessage('Error searching Mpesa code, try again!!.');
    } finally {
        setChecking(false);
    }
};



  const handleCompleteOrder = async () => {
    setLoading(true);
   const finalCheckout=localStorage.getItem('checkoutData');

  // console.log('complete order ',finalCheckout);
    
    try {
     // console.log("Complete Order Data:", JSON.stringify(finalCheckout)); // Log data being sent
  
      const response = await fetch('https://backoffice.route.money/api/v1/business/order/create/complete/v2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:finalCheckout, // Ensure data is properly serialized
      });
  
      const responseData = await response.json();
    //  console.log('Response Data:', responseData); // Log response data
  
      if (response.ok) {
      
    // Store the response data in localStorage after stringifying it
       localStorage.setItem('paymentResponse', JSON.stringify(responseData));
        setMessageColor('green');
         navigation.navigate('PaymentResponsescreen');
        localStorage.removeItem('paymentSuccess');
      } else {
        const errorMessage = responseData.error || 'Failed to complete the order, please try again.';
        setMessageColor('orange');
        setMessage(errorMessage);
       // alert(errorMessage);
       setErrorMessage(errorMessage)

       setShowMessage(true);
       const timer = setTimeout(() => {
         setShowMessage(false);
       }, 10000); // Display for 3000ms (3 seconds)
   
       return () => clearTimeout(timer); // Cleanup on unmount
 
   
      }
    } catch (error) {
      console.error('Error completing the order:', error);
      setMessageColor('orange');
      setMessage('An error occurred while completing the order.');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    decodePaymentResponse();
  }, []);



  const handleInputChange = (text) => {
    setUserRef(text);
  };

  const handleHome = async () => {
   // localStorage.removeItem('checkoutData');
    let currentURL = window.location.href;
    const url = new URL(currentURL);
    url.searchParams.delete('response');
    const updatedURL = url.toString();

    window.history.replaceState(null, '', updatedURL);
    navigation.navigate("HomeScreen");
  };

  return (
    <Layouts>
      <View style={styles.container}>
        <View style={styles.topStatus}>
          {/* ERROR MESSAGE */}
          {showMessage && (
         <View style={styles.messageContainer}>
         <Text style={styles.messageText}>
           {erroMessages}
         </Text>
        </View>
        )}

          {messageColor === 'green' && (
            <>
             <View style={styles.successStatus}>
              <ActivityIndicator size={65} color="green" />
              <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20 }}>
                {/* You have successfully paid Ksh. {amount} */}
                Processing ...
              </Text>
              {/* <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20 }}>
                Transaction Ref #{transactionRef}
              </Text> */}
              </View>
            </>
          )}

          {messageColor === 'orange' && (
            <>
              <AlertCircle size={70} color="orange" />
              <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20, color: messageColor }}>
                {insystemMessage}
              </Text>


      
              <View style={styles.inputContainer}>
                <TextInput
                  placeholder="Enter Mpesa Ref code"
                  style={styles.paymentsInputs}
                  value={userRef}
                  onChangeText={handleInputChange} // Handle text input change
                />
              </View>
            </>
          )}

          {messageColor === 'red' && (
            <>
              <AlertCircle size={70} color="red" />
              <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20, color: 'orange' }}>
                {insystemMessage}
              </Text>
  
            </>
          )}
        </View>
        
        <View style={styles.buttonContainer}>
       

          {messageColor === 'orange' && (
            <>
            <Button
                text={checking ? "Searching ..." : "Search"}
              mode="outlined"
              onPress={handleReferenceSearch}
              style={styles.button}
              loading={checking}
            >
              {/* {checking ? 'Searching...' : 'Search'} */}
            </Button>
            <TouchableOpacity onPress={handleHome}>
            <Text style={{ color: COLORS.blue, textAlign: 'center', marginTop: 10 }}>
            Home
            </Text>
            </TouchableOpacity>
      </>
          )}

          {messageColor === 'red' && (
            <>
              <Button
                text={checking ? "processing..." : "Home"}
                mode="outlined"
                onPress={handleHome}
                style={styles.button}
              >
              
                {/* Home */}
              </Button>
              <TouchableOpacity onPress={handleCompleteOrder}>
                <Text style={{ color: COLORS.blue, textAlign: 'center', marginTop: 10 }}>
                  Try Again
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
        {/* modal  */}
       
      </View>
    </Layouts>
  );
}

export default CheckoutComplete;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 40,
    justifyContent: "space-between",
    maxWidth:430
  },
  topStatus: {
    alignItems: "center",
    marginTop: "25%"
  },
  successStatus:{
     marginTop: "35%"
  },
  inputContainer: {
    flexDirection: "row",
    width:"90%",
    marginTop:50,
    alignItems: "center",
    marginVertical: 20,
  },
  paymentsInputs: {
    height: 40,
    width: "100%",
    borderColor: COLORS.blue,
    backgroundColor:COLORS.gray,
    color:'white',
    borderWidth: 1,
    borderRadius: 50,
    paddingHorizontal: 10,
  },
  buttonContainer: {
    paddingVertical: 20,
    width:"30%",
     marginLeft:140
   
  },
  button: {
    marginVertical: 5,

  },
  // message styles
  messageContainer: {
    backgroundColor: '#FFCCCB', // Soft red background
    borderRadius: 5,
    padding: 10,
    margin: 5,
    marginLeft:10,
    borderColor: '#FFCCCB',
    borderWidth: 2, // Outline effect
    shadowColor: 'rgba(0, 0, 0, 0.2)', // Optional: shadow for a slight lift effect
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
  },
  messageText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: '#A00000', // Darker red for better visibility
    fontWeight: 'bold', // Make text bold
  },
});