import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image} from "react-native";
import React, { useState } from "react";
import { useCart } from "../../../../context/SinglecartContext";

function SingleCartProduct({ navigation, data }) {

  const { dispatch, getQuantityOfItem } = useCart();

  // Adding to cart and posting
  const addToCart = async (product) => dispatch({ type: 'ADD_TO_CART', payload: product });

  // Remove from cart
  const removeFromCart = (product) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: product });
  };

  return (
    <View>
     <TouchableOpacity style={styles.singleOfferView}>
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <View style={styles.singleOfferImg}><Image source={data.image} style={styles.singleOfferTagImg}/></View>
            <View style={styles.offertextsDesc}>
              <Text style={styles.amountOffer}>{data.name}</Text>
              {
                data.discount_percentage === null ? "" :<Text style={{...FONTS.smallfineText, backgroundColor: "#11A20B", paddingVertical: 2, paddingHorizontal: 10, marginVertical: 2, color: "green", borderRadius: 100, fontSize: 11}}>On Offer</Text>
              }
              {data.orderType === "table" && <Text style={{...FONTS.smallfineText, backgroundColor: "skyblue", paddingVertical: 2, paddingHorizontal: 10, marginVertical: 2, color: "green", borderRadius: 100, fontSize: 11}}>Brought to table</Text>}
            </View>
        </View>
        <View style={styles.handleChnageCart}>
            <Text style={styles.cartProductAmount}>KSH {data.discount_percentage === null ? data.price : data.offer_price}</Text>
            <View style={styles.cartProductActionbtns}>
                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => removeFromCart(data)}><Text style={styles.actions}>-</Text></TouchableOpacity>
                <Text style={styles.cartDisplayCapacity}>{getQuantityOfItem(data.id)}</Text>
                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addToCart(data)}><Text style={styles.actions}>+</Text></TouchableOpacity>
            </View>
        </View>
        
     </TouchableOpacity>
    </View>
  );
}

const { width, height } = Dimensions.get("screen")
const styles = StyleSheet.create({
  singleOfferView: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    height: 70,
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 10,
  },
  singleOfferImg: {
    height: 60,
    width: 60,
    birderRadius: 10,
    overflow: "hidden",
  },
  singleOfferTagImg: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
  offertextsDesc: {
    marginLeft: 15,
  },
  amountOffer: {
    ...FONTS.fineText,
    fontSize: 14,
  },
  cartProductAmount: {
    ...FONTS.fineText,
    fontSize: 11,
    paddingBottom: 10,
  },
  handleChnageCart: {
    justifyContent: "center",
    alignItems: "center",
  },
  cartProductActionbtns: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  cartBtnsOutline: {
    width: 15,
    height: 15,
    textAlign: "center",
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    ...FONTS.bigsubtext,
    fontSize: 11,
  },
  cartDisplayCapacity: {
    marginHorizontal: 5,
    ...FONTS.fineText,
  },
});
export default SingleCartProduct;
