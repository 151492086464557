// LAternative providesr
import React, { createContext, useEffect, useState, useContext } from 'react';
import { COLORS } from '../constants';
import { jwtDecode } from 'jwt-decode';

const MerchantTokenContext = createContext(null);

const useMerchantToken = () => {
  return useContext(MerchantTokenContext);
};

const MerchantTokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [values, setValues] = useState("");
  const [profile, setUserProfile] = useState();

  // Decode the token and get user profile
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get('provider');
    setToken(authToken);

    if (authToken) {
      const decodedToken = jwtDecode(authToken);
      const username = decodedToken.username;
      setUserProfile(username);

      console.log('Decoded Token:', decodedToken);
      console.log('Username:', username);
    }
  }, [token]);


  // Getting user input amount from the purchase of products
  // const getUserPurchaseAmount = (amount) => {
  //   if (!amount || amount === "" || amount === "undefined") {
  //     alert("You must enter the amount to continue!!!")
  //   }else{
  //     setValues(amount)
  //   }
  // }

  // // Transactions begins here
  // let configuration = {
  //   // iOS Properties
  //   dismissButtonStyle: "cancel",
  //   preferredBarTintColor: COLORS.blue,
  //   preferredControlTintColor: "white",
  //   readerMode: false,
  //   animated: true,
  //   modalPresentationStyle: "popover",
  //   modalTransitionStyle: "coverVertical",
  //   modalEnabled: true,
  //   enableBarCollapsing: false,

  //   // Android Properties
  //   showTitle: true,
  //   toolbarColor: COLORS.blue,
  //   secondaryToolbarColor: "black",
  //   navigationBarColor: "black",
  //   navigationBarDividerColor: "white",
  //   enableUrlBarHiding: true,
  //   enableDefaultShare: true,
  //   forceCloseOnRedirection: true,
  //   forceCloseOnRedirection: true,
  //   forceCloseOnRedirection: true,

  //   // Specify full animation resource identifier(package:anim/name)
  //   // or only resource name(in case of animation bundled with app).
  //   animations: {
  //     startEnter: "slide_in_right",
  //     startExit: "slide_out_left",
  //     endEnter: "slide_in_left",
  //     endExit: "slide_out_right",
  //   },
  //   headers: {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   },
  //   navigation: "yes",
  //   hideToolbar: "no",
  // };

  // const options = {
  //   transactionReference: `ROUTE_TZD_${(Math.random() + 1)
  //     .toString(36)
  //     .substring(9)}`.toUpperCase(),
  //   merchantCode: "ROUTEK0001",
  //   currencyCode: "KES",
  //   amount: Number(values) * 100,
  //   orderId: profile?.wallet_account?.wallet_account, //get this value from the profile
  //   terminalType: "WEB",
  //   redirectUrl: "#",
  //   domain: "ISWKE",
  //   customerId: profile.id, //route tag name
  //   customerFirstName: profile.first_name, //user registration first name
  //   customerSecondName: profile.last_name, //user registration last name
  //   customerEmail: profile.email, //user registration email
  //   customerMobile: profile.phone_number, // user registration phone number
  //   customerCity: "Nairobi",
  //   customerCountry: "KE",
  //   customerState: "NBI",
  // };

  // const loadMoneyTwo = () => {
  //   axios
  //     .post(
  //       "https://gatewaybackend.quickteller.co.ke/ipg-backend/api/checkout",
  //       options,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       },
  //     )
  //     .then(response => response.request.responseURL)

  //     .then(url => {
  //       console.log(url);
  //       return InAppBrowser.open(url, configuration);
  //     })
  //     .catch(error => console.error(error));
  //   return;
  // };

  // const handleAddMoney = () => {
  //   if (values.length == 0) {
  //     return Alert.alert("Please enter an amount to continue");
  //   } else if (Number(values) < 50) {
  //     return Alert.alert("Minimum amount is KES 50");
  //   } else {
  //     loadMoneyTwo();
  //   }
  // };

  // Export values and functions
  const toExportValues = {token };

  return (
    <MerchantTokenContext.Provider value={toExportValues}>
      {children}
    </MerchantTokenContext.Provider>
  );
};

export { MerchantTokenProvider, useMerchantToken };

