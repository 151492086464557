import React from "react";
import { StyleSheet, Text, TouchableOpacity, Dimensions } from "react-native";
import { COLORS, FONTS } from "../constants";

const Button = ({ text = "Create An Account", style, textStyle, ...rest }) => {
  return (
    <TouchableOpacity style={[styles.button, style]} {...rest}>
      <Text style={[styles.text, FONTS.smallfineText, textStyle]}>{text}</Text>
    </TouchableOpacity>
  );
};

export default Button;

const { height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  button: {
    backgroundColor: COLORS.blue,
    height: (53 / 932) * height,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
  },
  text: {
    color: "white",

    fontSize: 10,
  },
});
