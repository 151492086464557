import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, TextInput, Text, Picker,  ActivityIndicator,FlatList} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, CheckCircle2} from 'lucide-react';
import { TouchableOpacity } from "react-native-web";
import axios from "axios";
import { useMerchantStore } from "../../../../store/merchant_store";

function Forms({route,navigation, id, modifyTableCapacity, count,
    setFullName, fullName,
    setReservationTable,reservationTable,
    userEmail, setUseEmail,
    userPhoneNumber, setUserPhoneNo,
    seatingPosition, setSeatingPosition,
    tableTime, setTableTime, 
    numberOfGuests, setNumberOfGuests, 
    pickupTime, setPickUpTime,
    userAddress, setUserAddress,
    errors
}) {
  
  // get current user and generate tables
  const merchantStore = useMerchantStore((state) => state.merchant);
  const { merchantData } = route.params || merchantStore;
  const [tables, setTables] = useState([]);
  const [loadingTables, setLoadingTables] = useState(true);
  const [filteredTables, setFilteredTables] = useState([]);
  const [tableError, setTableError] = useState("");
  const [inputText, setInputText] = useState('');
   const [showDropdown, setShowDropdown] = useState(false); 

   const inputRef = useRef(null);

  
   // Fetch tables
//    useEffect(() => {
//     const fetchTables = async () => {
//         try {
//             const response = await axios.get(`https://backoffice.route.money/api/v1/business/merchant/tables/${merchantData.business}`);
//             setTables(response.data.results); // Adjust according to your API response structure
//         } catch (error) {
//             console.error("Failed to fetch tables:", error);
//         } finally {
//             setLoadingTables(false);
//         }
//     };

//     fetchTables();
// }, []); // Add dependencies if needed

useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await axios.get(`https://backoffice.route.money/api/v1/business/merchant/tables/${merchantData.business}`);
        setTables(response.data.results); // Adjust according to your API response structure
        setFilteredTables(response.data.results); // Initially, all tables are available in the dropdown
      } catch (error) {
        console.error("Failed to fetch tables:", error);
      } finally {
        setLoadingTables(false);
      }
    };

    fetchTables();
  }, []);


// handle inputs 
// const handleInputChange = (text) => {
//     setInputText(text);

//     // Check if the text exists in the table list
//     const isTableNameExists = tables.some(table =>
//       table.name.toLowerCase().includes(text.toLowerCase())
//     );

//     if (isTableNameExists) {
//         // Filter the tables based on the input text
//         const filtered = tables.filter(table =>
//           table.name.toLowerCase().includes(text.toLowerCase())
//         );
//         setFilteredTables(filtered);
//         setTableError(''); // Clear any previous error messages
//     } else {
//         setFilteredTables([]); // Clear the filtered tables
//         setTableError('Table does not exist, Consider selecting from the dropdown'); // Set error message
//     }
// };

const handleOutsidePress = () => {
  setShowDropdown(false);
};

const handleInsidePress  = () => {
  setShowDropdown(true);
};

const handleBlur = () => {
  // Delay hiding dropdown to allow click event to register
  setTimeout(() => {
    setShowDropdown(false);
  }, 100);
};

const handleInputChange = (text) => {
  setInputText(text);
  setShowDropdown(true); // Show dropdown when typing

  const filtered = tables.filter(table =>
      table.name.toLowerCase().includes(text.toLowerCase())
  );
  setFilteredTables(filtered);
console.log('selected table ',filtered);
  if (filtered.length === 0) {
      setTableError('Table does not exist, consider selecting from the dropdown');
  } else {
      setTableError('');
  }
};


const handleInputChangeDesc = (text) => {
  setInputText(text);
  setShowDropdown(true); // Show dropdown when typing

  const filtered = tables.filter(table =>
      table.description.toLowerCase().includes(text.toLowerCase())
  );
  setFilteredTables(filtered);
console.log('selected table ',filtered);
  if (filtered.length === 0) {
      setTableError('Table does not exist, consider selecting from the dropdown');
  } else {
      setTableError('');
  }
};


const handleSelectItem = (tableName) => {
  setInputText(tableName); // Set the selected table name in the input field
  const selectedTable = tables.find(table => table.name === tableName);
  if (selectedTable) {
    setInputText(selectedTable.name); // Show the selected table name in the input field
    setSeatingPosition(selectedTable.id); // Store the selected table id
  }
  console.log('handle selected ',selectedTable)
  setShowDropdown(false); // Hide the dropdown
  setTableError(''); // Clear any error messages
};

const handleSelectdesc = (desc) => {
  setInputText(desc); // Set the selected table name in the input field
  const selectedTable = tables.find(table => table.description === desc);
  if (selectedTable) {
    setInputText(selectedTable.description); // Show the selected table name in the input field
    setSeatingPosition(selectedTable.id); // Store the selected table id
  }
  console.log('handle selected ',selectedTable)
  setShowDropdown(false); // Hide the dropdown
  setTableError(''); // Clear any error messages
};


// const handlePickerChange = (tableName) => {
//     const selectedTable = tables.find(table => table.name === tableName);
//     if (selectedTable) {
//       setInputText(selectedTable.name); // Show the selected table name in the input field
//       setSeatingPosition(selectedTable.id); // Store the selected table id
//     }
//   };

  // generate time
  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      options.push(`${hour}:00`);
    }
    return options;
  };
  const timeOptions = generateTimeOptions();

//   error messages



// error messages

  return (
   
    <>
     <TouchableOpacity
    activeOpacity={1}
    onPress={handleOutsidePress}
    style={{ flex: 1 }}
  >
    {
        (id === "IN-STORE" || id === "RESERVE" || id === "PICK-UP" || id === "DELIVERY")?  
       
       <View style={styles.inputs}>
            {/* <TextInput placeholder="Enter your name" style={styles.paymentsInputs} value={fullName} onChangeText={(name) => setFullName(name)}/>
            <TextInput placeholder="Enter your email" style={styles.paymentsInputs} value={userEmail} onChangeText={(email) => setUseEmail(email)}/>
            <TextInput placeholder="Enter your Phonenumber" style={styles.paymentsInputs} value={userPhoneNumber} onChangeText={(phone) => setUserPhoneNo(phone)}/> */}

         <View style={styles.inputContainer}>
            <TextInput
              placeholder="Enter your name"
              style={[
                styles.paymentsInputs,
                errors.fullName && styles.errorInput
              ]}
              value={fullName}
              onChangeText={(name) =>
                 setFullName(name)}
            />
            {errors.fullName && <Text style={styles.errorText}>{errors.fullName}</Text>}
 
          </View>
          <View style={styles.inputContainer}>
            <TextInput
              placeholder="Enter your email"
              style={[
                styles.paymentsInputs,
                errors.userEmail && { borderColor: 'red' }
              ]}
              value={userEmail}
              onChangeText={(email) => setUseEmail(email)}
            />
            {errors.userEmail ? <Text style={styles.errorText}>{errors.userEmail}</Text> : null}
          </View>
          <View style={styles.inputContainer}>
            <TextInput
              placeholder="Enter your phone number"
              style={[
                styles.paymentsInputs,
                errors.userPhoneNumber && { borderColor: 'red' }
              ]}
              value={userPhoneNumber}
              onChangeText={(phone) => setUserPhoneNo(phone)}
            />
            {errors.userPhoneNumber ? <Text style={styles.errorText}>{errors.userPhoneNumber}</Text> : null}
          </View>

          {
                id === "IN-STORE" && <>
             <TouchableOpacity
                  activeOpacity={1}
                  onPress={handleInsidePress}
                  style={{ flex: 1 }}
                  >
              <View style={styles.cutomizeFlexInputSearch}>
            <View style={styles.inputwithChevron}>
              <TextInput
                placeholder="Enter Table/ Seat/ Room"
                style={styles.paymentsInputs}
                value={inputText}
                onChangeText={handleInputChange} // Handle text input change
                onFocus={() => setShowDropdown(true)} // Show dropdown when the input is focused
                onBlur={handleBlur}
            />
             <ChevronDown style={styles.chevronIcon} size={20} color="black" />
            </View>
                        <View style={styles.spacer} />
                        {showDropdown && filteredTables.length > 0 && (
                <FlatList
                    style={styles.dropdown}
                    data={filteredTables}
                    keyExtractor={(item) => item.id.toString()}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            onPress={() => handleSelectItem(item.name)}
                            style={styles.dropdownItem}
                        >
                            <Text>{item.name}</Text>
                        </TouchableOpacity>
                    )}
                />
            )}
                        </View>
                        {tableError ? <Text style={styles.errorText}>{tableError}</Text> : null} {/* Show error message */}
            
                        </TouchableOpacity>
                </>
          }
            {/* Table */}
            {
                id === "RESERVE" && <>
             <TouchableOpacity
                  activeOpacity={1}
                  onPress={handleInsidePress}
                  style={{ flex: 1 }}
                  >
              <View style={styles.cutomizeFlexInputSearch}>
            <View style={styles.inputwithChevron}>
              <TextInput
                placeholder="Enter Table/ Seat/ Room"
                style={styles.paymentsInputs}
                value={inputText}
                onChangeText={handleInputChangeDesc} // Handle text input change
                onFocus={() => setShowDropdown(true)} // Show dropdown when the input is focused
                onBlur={handleBlur}
            />
             <ChevronDown style={styles.chevronIcon} size={20} color="black" />
            </View>
                        <View style={styles.spacer} />
                        {showDropdown && filteredTables.length > 0 && (
                <FlatList
                    style={styles.dropdown}
                    data={filteredTables}
                    keyExtractor={(item) => item.id.toString()}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            onPress={() => handleSelectdesc(item.description)}
                            style={styles.dropdownItem}
                        >
                            <Text>{item.description}</Text>
                        </TouchableOpacity>
                    )}
                />
            )}
                        </View>
                        {tableError ? <Text style={styles.errorText}>{tableError}</Text> : null} {/* Show error message */}
            
                        {errors.seatingPosition ? <Text style={styles.errorText}>{errors.seatingPosition}</Text> : null}
             
                        </TouchableOpacity>
              
             <View style={styles.tableFlex}>
             <View style={styles.cutomizeFlex}>
                    {/* <TextInput placeholder="Time" value={tableTime} style={[styles.cutomizeInput,errors.tableTime && styles.errorInput]} onChangeText={(time) => setTableTime(time)}/>
                  */}
                    <Picker
                    style={{backgroundColor: "transparent", border: "none", color: "gray"}}
                    onValueChange={(itemValue, itemIndex) => setTableTime(itemValue)}
                    >
                         <Picker.Item label="Select Time  00:00" value="" />
                        {timeOptions.map((time, index) => (
                        <Picker.Item key={index} label={time} value={time}/>
                        ))}
                    </Picker>
                </View>
                <View style={styles.cutomizeFlex}>
                    <TextInput placeholder="Number Of Guests" style={styles.paymentsInputs} value={numberOfGuests} onChangeText={(guests) => setNumberOfGuests(guests)}/>
                    {/* <View style={styles.tableFlexBottom}>
                        <TouchableOpacity style={styles.btns} onPress={() => modifyTableCapacity("-")}><Text style={styles.actions}>-</Text></TouchableOpacity>
                        <Text style={styles.outputs}>{count}</Text>
                        <TouchableOpacity style={styles.btns} onPress={() => modifyTableCapacity("+")}><Text style={styles.actions}>+</Text></TouchableOpacity>
                    </View> */}
                </View>
               
               </View>
               {errors.tableTime ? <Text style={styles.errorText}>{errors.tableTime}</Text> : null}
            </>
            }

            {
                id === "DELIVERY" && <View>
                  {/* <TextInput placeholder="Address" style={[styles.paymentsInputs,errors.userAddress && styles.errorInput]} value={userAddress} onChangeText={(Address) => setUserAddress(Address)}/>
                  */}
                  <TextInput placeholder="Address" style={styles.paymentsInputs} value={userAddress} onChangeText={(address) => setUserAddress(address)}/>
                  {errors.userAddress ? <Text style={styles.errorText}>{errors.userAddress}</Text> : null}
             </View>
            }
            
            {
                id === "PICK-UP" &&  <View style={styles.cutomizeFlexOne}>
                    {/* <TextInput placeholder="Time" value={pickupTime} style={[styles.cutomizeInput,errors.pickupTime && styles.errorInput]} onChangeText={(picktime) => setPickUpTime(picktime)}/>
                   */}
                    <Picker
                    style={{backgroundColor: "transparent", border: "none", color: "gray"}}
                    onValueChange={(itemValue, itemIndex) => setTableTime(itemValue)}
                    >
                         <Picker.Item label="Select Time  00:00" value="" />
                        {timeOptions.map((time, index) => (
                        <Picker.Item key={index} label={time} value={time}/>
                        ))}
                    </Picker>
                    {errors.pickupTime ? <Text style={styles.errorText}>{errors.pickupTime}</Text> : null}
                </View>
            }
            {
                (id === "RESERVE" || id === "PICK-UP" || id === "DELIVERY" ) && <>
                {/* <View style={styles.whatsappOrder}>
                    <TouchableOpacity style={styles.whatsapp}>
                      <CheckCircle2 size={18} color="green"/>
                    </TouchableOpacity>
                    <View style={{marginLeft: 10}}>
                        <Text style={{...FONTS.smallfineText, fontSize: 10, fontWeight: 700}}>WhatsApp order</Text>
                        <Text style={{...FONTS.smallfineText, fontSize: 10, color: COLORS.gray}}>Send order to our whatsApp</Text>
                    </View>
                </View> */}
      
                </>
            }
        </View> :
        <View style={styles.selections}>
            <Text style={{...FONTS.smallfineText, fontSize: 11, textAlign: "center"}}>Select the buttons above to customize much on the preference of payment of the products.</Text>
        </View>
    }
     </TouchableOpacity>  
    </>
  );
}

const styles = StyleSheet.create({
    whatsappOrder: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginVertical: 5,
        color: COLORS.gray,
        border: "none",
        width: "100%",
        marginTop: 8,
    },
    input: {
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 10,
      borderRadius: 5,
      fontSize: 16,
  },
    inputs: {
        marginTop: 5,
        maxWidth: 430,
    },
    dropdown: {
      backgroundColor: 'white',
      width: '100%',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: COLORS.gray,
      maxHeight: 200, // Limit dropdown height
      overflow: 'hidden',
      elevation: 5, // For shadow on Android
      zIndex: 1, // Ensure dropdown is above other content
    },
    dropdownItem: {
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: COLORS.lightGray,
    },
    dropdownText: {
      color: COLORS.textPrimary,
    },
    errorInput: {
      borderColor: 'red',
      borderWidth: 1,
    },
    errorText: {
      color: 'red',
      fontSize: 12,
    },
    paymentsInputs: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.white,
        border: "none",
        width: "100%",
    },
    spacer: {
        width: 10,
      },
    tableFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    cutomize: {
        width: "49%",
        padding: 10,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
    },
    cutomizeFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding:4,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
        width: "49%",
    },
    tableFlexBottom: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    cutomizeInput: {
        width: "70%",
        height:"100%",
        borderRadius: 10,
        border: "none",
        color: COLORS.gray,
    },
    btns: {
        width: 15,
        height: 15,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: COLORS.white,
        justifyContent: "center",
        alignItems: "center",
    },
    outputs: {
        ...FONTS.smallfineText,
        fontSize: 14,
        marginHorizontal: 5,
    },
    actions: {
        ...FONTS.smallfineText,
        fontSize: 14,
    },
    cutomizeFlexOne: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 4,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
        width: "100%",
    },
    cutomizeFlexInputSearch: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start", // Align dropdown below input
      padding: 4,
      borderRadius: 10,
      backgroundColor: COLORS.backgroundLight,
      marginVertical: 4,
      width: "100%",
    },
    selections: {
        width: "100%",
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderRadius: 10,
        backgroundColor: COLORS.green,
        marginVertical: 10,
        justifyContent: "center",
        alignItems: "center",
    },
    chevronIcon: {
      position: 'absolute',
      color: COLORS.gray,
      right: 10,
      zIndex: 1, // Ensure the icon appears above the text input
    },
    inputwithChevron:{
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
     width:'100%',
    },
});
export default Forms;
