import { COLORS, IMAGES, FONTS,MAINLOGO } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Image,
  Linking,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import Logo from "../../../assets/images/routelogo.svg";
import { powerlogo } from "./footer/Index";
import Layouts from "../../Layouts/ScreenView";
import { useCart } from "../../../context/SinglecartContext";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import axios from "axios";
import Svg, { SvgXml, Path } from "react-native-svg";
import { useMerchantStore } from "../../../store/merchant_store";
import mylogo from '../../../assets/images/bag.svg';
import { RiWhatsappFill } from 'react-icons/ri'; // Remix Icons
const { height: SCREEN_HEIGHT, width: SCREEN_WIDTH } = Dimensions.get('window'); // Get screen dimensions


let screenHeight;
function HomeScreen({ navigation, route }) {
  const [containerHeight, setContainerHeight] = useState(SCREEN_HEIGHT); // Default to full screen height

  const { deviceFrame } = useCart();
  const [merchantData, setMerchantData] = useState();
  const { merchant, addMerchant } = useMerchantStore();
  const [isPressed, setIsPressed] = useState(false); 
  // const { merchantSlug } =  route.params || {};
  const [order, setOrder] = useState(false);
  const [orderData, setOrderData] = useState({});
//SJG9BC8MLP

  useEffect(() => {
    // Update container height on screen size changes (optional, in case orientation changes)
    const handleResize = () => {
      const { height } = Dimensions.get('window');
      setContainerHeight(height); // Update container height
    };

    // Add event listener for screen resize
    const dimensionsSubscription = Dimensions.addEventListener('change', handleResize);

    // Clean up the listener
    return () => dimensionsSubscription?.remove();
  }, []);

 
 const logo3=`
 <svg width="157" height="186" viewBox="0 0 157 186" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_6965_633" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="157" height="186">
<path d="M21.5949 91.0547C21.5949 91.0547 21.5949 90.7861 21.6382 90.7413L32.5165 84.2956C32.9932 84.0271 33.2533 83.5347 33.2533 82.9975V31.9691H86.3445C109.011 31.9691 122.013 42.6224 122.013 61.2433V61.7805C122.013 79.7299 108.101 91.3232 86.6046 91.3232H21.8983C21.8983 91.3232 21.6382 91.189 21.5949 91.0994M5.94922 0.993977C2.91543 0.993977 0.488403 3.5454 0.488403 6.63397V179.414C0.488403 182.503 2.95877 185.054 5.94922 185.054H27.7925C30.8263 185.054 33.2533 182.548 33.2533 179.414V121.537H79.5835L121.103 182.324C122.273 184.025 124.18 185.054 126.174 185.054H149.881C152.221 185.054 154.345 183.712 155.428 181.563C156.512 179.414 156.338 176.863 154.951 174.893L114.039 115.629C140.649 107.258 155.341 87.4737 155.341 59.9452V59.4081C155.341 43.6072 150.184 29.7758 140.433 19.4358C128.471 7.35015 110.615 0.949219 88.9016 0.949219H5.94922V0.993977Z" fill="white"/>
</mask>
<g mask="url(#mask0_6965_633)">
<path d="M115.392 -69.9674L-78.0107 49.4375L41.5592 256.025L234.962 136.62L115.392 -69.9674Z" fill="url(#paint0_linear_6965_633)"/>
</g>
<defs>
<linearGradient id="paint0_linear_6965_633" x1="43.0382" y1="27.6402" x2="180.341" y2="250.032" gradientUnits="userSpaceOnUse">
<stop stop-color="#E006FF"/>
<stop offset="1" stop-color="#1677F2"/>
</linearGradient>
</defs>
</svg>
 `;

 const logo2=``
 const [isHovered, setIsHovered] = useState(false);

 const handleNavigateToURL = () => {
  setIsPressed(false);
  const url = 'https://linktr.ee/route.money/'; // Replace with your desired URL
  Linking.openURL(url).catch(err => console.error('Failed to open URL:', err));
};

const containerStyle = [
  styles.footerstyles,
  isHovered && styles.hoveredContainer // Apply hovered style when isHovered is true
];


  useEffect(() => {
    // localStorage.removeItem('checkoutData');
  

    const searchParams = new URLSearchParams(window.location.search);
    const slugParam = searchParams.get("merch");
    const orderParam = searchParams.get("order");
    const responseParam = searchParams.get('response');
    const searchOrderParam = searchParams.get('searchOrder');
   
    if (responseParam) {

      navigation.navigate('CheckoutCompleteScreen');
       
    } 
  
    if (searchOrderParam) {
      navigation.navigate("OrderScreen")
    }

    if (orderParam) {
      setOrder(true);
      let new_data = JSON.parse(JSON.stringify([orderParam]));
      // setOrderData(new_data);
      // alert(new_data.total_amount);
    } else {
      setOrder(false);
      // setOrderData({});
    }
    fetchUserBusinesses(slugParam);
  }, []);

  const fetchUserBusinesses = async (slug) => {
    try {
      const response = await axios.get(
       `https://backoffice.route.money/api/v1/business/merchant/detail/${slug}`

      );
      setMerchantData(response.data);
     // console.log(`Merchant Data inZustand: ${merchant}`);
      addMerchant(response.data);

      localStorage.setItem("merchantData", JSON.stringify(response.data));
     // console.log("merch data: ", response.data);
    } catch (error) {
      console.error("Failed to fetch user single business data:", error);
    }
  };

  //console.log(`Merchant Data in Zustand---: ${JSON.stringify(merchant)}`);
  //console.log("merchant wallet ",merchant.wallet);
  return (
    // <Layouts style={{flex:1}}>
    <Layouts>

    <View style={[styles.container,{ height:"50%" }]}>
    {merchantData ? (
          <View
            style={{ justifyContent: 'space-between', alignItems: "center"}}
          >
            <View style={styles.qrcodeimage}>
              <Image
                source={`https://backoffice.route.money/api/v1${merchantData.profile_image}`}
                style={styles.qrimage}
              />
            </View>



            {orderData.length > 0 ? (
              <View style={styles.buttoncontainer}>
                <Button
                  text={"Pay Ksh. 3.56"}
                  style={styles.button}
                  onPress={() =>
                    navigation.navigate("OfferScreen", { merchantData })
                  }
                />
              </View>
            ) : (
              <View style={styles.buttoncontainer}>
                <Button
                  text="View Menu"
                  style={styles.button}
                  onPress={() =>
                    navigation.navigate("OfferScreen", { merchantData })
                  }
                />
              </View>
            )}
          </View>
        ) : (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "64px",
              marginVertical: "auto",
            }}
          >
            {/* <ActivityIndicator /> */}
            <ActivityIndicator size={45} color="blue" />
          </View>
        )}

  {/* footer */}
  <View style={styles.footerSec}>
  <TouchableOpacity
      style={[
        styles.footersstyles,
         // Dynamic border color based on touch state
      ]}
      onPress={() => {
        setIsPressed(true); // Set touch state to true
        handleNavigateToURL(); // Handle navigation on press
      }}
      onPressOut={() => setIsPressed(false)} // Reset touch state on press release
    >
       <View style={styles.footersstyles}>
       <View style={styles.footerimagecontainer}>
            {/* powerlogo */}
            <img
               src={`data:image/svg+xml;utf8,${encodeURIComponent(powerlogo)}`}
              className="App-logo"
              alt="logo"
              style={styles.routeLogo}
              // style={styles.cardLogo}
            />
          </View>
       </View>
    
        </TouchableOpacity>
    </View>
    </View>
        </Layouts>

//  {/* </Layouts> */}
  );
}

const { width,height } = Dimensions.get("screen");


const styles = StyleSheet.create({

  container: {
    flex: 1,
    width: wp(100),
    maxWidth: 430,
    height:"100%",
    justifyContent: "center",  // Center content vertically
    alignItems: "center",  // Center content horizontally
    paddingVertical: 20, // Adds padding to push content down a bit
  },

  // container: {
  //   flex:1,
  //   width: wp(100),
  //  // height: hp(100),
  //  height:'100%',
  //   justifyContent: "center",
  //   justifyContent: 'space-between', 
  //   alignItems: "center",
  // // backgroundColor: COLORS.backgroundDark,
  // // backgroundColor:"green",
  //  maxWidth: 430,
   
  // },
  qrcodeimage: {
    width: 200,
    height: 200,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    borderRadius: 5,
    marginVertical: 20,
  },

  // qrcodeimage: {
  //   width: 200,
  //   height: 200,
  //   marginTop:90,
  //   justifyContent: "flex-start",
  //   alignItems: "flex-start",
  //  backgroundColor: "black",
  //   borderRadius: 5,
  //   marginVertical: 20,
  // },
  // qrimage: {
  //   width: "100%",
  //   height: "100%",
  //   borderRadius: 5,
  // },
  qrimage: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
  },
  routeLogo: {
    width: 150,
    height: 100,
    marginRight: 5,
  },
  buttoncontainer: {
    paddingTop: 100,
    marginBottom: 10, // Adjust this value to increase/decrease padding between button and footer
},

  // button: {
  //   backgroundColor: COLORS.blue,
  //   borderWidth: 1,
  //   borderColor: COLORS.blue,
  //   marginVertical: 10,
  //   width: 270,
  // },

  button: {
    backgroundColor: COLORS.blue,
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 10,
    width: 270,
  },
  footerContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: 'center',
    paddingVertical: 10,
    paddingTop: 15, // Extra padding to add space between button and footer
   // backgroundColor: COLORS.backgroundDark,
   //backgroundColor:"green"
},
footerSec:{
paddingTop:"33%",
height: "20%",

},

  footerInnerContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  routeLogo: {
    width: 150,
    height: 70,
    marginRight: 8,  // Space between logo and text
  },
  text: {
    color: "white",
    fontSize: 15,
  },
  viewButton: {
    marginLeft: 8, // Space between text and button
  },
});
export default HomeScreen;
